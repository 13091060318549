// Styles

// Main styles

@import './bootstrap.scss';
@import './app.scss';

// Agrostrong Icons
@import './icons.scss';

/* ltn__drop-menu */
.ltn__drop-menu {
  display: inline-block;
  text-align: left;
  align-items: center;
  display: flex;
}

.ltn__drop-menu li {
  margin-top: 0;
}

.ltn__drop-menu + .ltn__drop-menu {
  margin-left: 10px;
}

.ltn__drop-menu ul {
  margin-bottom: 0;
}

.ltn__drop-menu > ul > li {
  display: inline-block;
  position: relative;
}

.ltn__drop-menu > ul > li > a {
  display: block;
  text-decoration: none;
  padding: 0 10px;
  height: 40px;
  line-height: 40px;
}

.ltn__drop-menu ul li ul {
  position: absolute;
  min-width: 150px;
  right: 0;
  background-color: #fff;
  z-index: 999;
  -webkit-box-shadow: var(--ltn__box-shadow-4);
  box-shadow: var(--ltn__box-shadow-4);
  border-top: 1px solid #ececec;
  top: 130%;
  padding: 10px 0;
  margin: 0;
  -webkit-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  -o-transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  transition: opacity 0.2s ease 0.1s, visibility 0.2s ease 0.1s,
    top 0.2s ease 0.1s;
  opacity: 0;
  display: flex;
  flex-direction: column;
  justify-content: space-around;
  flex-wrap: wrap;
  visibility: hidden;
  height: 258px;
  width: 400px;
  border:1px solid #eee;
  margin-top: 5px;
  /* margin-right:-280px; */
}



.ltn__drop-menu ul li ul li {
  display: block;
  padding: 5px 15px;
  font-size: 16px;
  color: var(--ltn__paragraph-color);
  font-weight: 500;
}

.ltn__drop-menu ul li ul li a {
  color: inherit;
}

.ltn__drop-menu ul li:hover > ul {
  top: 100%;
  opacity: 1;
  visibility: visible;
}

.ltn__drop-menu ul li:hover > a {
  color: var(--ltn__secondary-color);
}