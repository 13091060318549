// 
// card.scss
//

body{
font-family: 'Montserrat';
}

p{
font-family: 'Montserrat';

}
.card {
    margin-bottom: $grid-gutter-width;
}

h4{
font-family: 'Montserrat';
}

h6{
font-family: 'Montserrat';
}

.card-link + .card-link{
    /*rtl:ignore*/
    margin-left: $card-spacer-x;
    /*rtl:ignore*/
    margin-right: 0;
}

.card-drop {
    font-size: 20px;
    line-height: 0;
    color: inherit;
}

.card-widgets {
    float: right;
    height: 16px;
    > a {
        color: inherit;
        font-size: 18px;
        display: inline-block;
        line-height: 1;

        &.collapsed {
            i {
                &:before {
                    content: "\F0415";
                }
            }
        }
    }
}


// Card title / Card Header
.card-title,
.card-header {
    margin-top: 0;
}


//Card disable loading (Custom Cards)
.card-disabled {
    position: absolute;
    left: 0;
    right: 0;
    top: 0;
    bottom: 0;
    border-radius: $card-border-radius;
    background: $card-overlay-bg;
    cursor: progress;

    .card-portlets-loader {
        background-color: $card-overlay-color;
        animation: rotatebox 1.2s infinite ease-in-out;
        height: 30px;
        width: 30px;
        position: absolute;
        left: 50%;
        top: 50%;
        border-radius: 3px;
        margin-left: - ($grid-gutter-width * 0.5);
        margin-top: - ($grid-gutter-width * 0.5);
    }
}

@keyframes rotatebox {
    0% {
        transform: perspective(120px) rotateX(0deg) rotateY(0deg);
    }
    50% {
        transform: perspective(120px) rotateX(-180.1deg) rotateY(0deg);
    }
    100% {
        transform: perspective(120px) rotateX(-180deg) rotateY(-179.9deg);
    }
}

// Custom card box
.card-box {
    background-color: $card-bg;
    padding: $card-spacer-x;
    box-shadow: $box-shadow-sm;
    margin-bottom: $grid-gutter-width;
    @include border-radius($card-border-radius);
}

.header-title {
    font-size: 15px;
    margin: 0 0 7px 0;
    font-weight: 600;
    text-transform: uppercase;
}

.sub-header {
    font-size: 0.875rem;
    margin-bottom: $grid-gutter-width;
    color: $text-muted;
}

.product{
    background-color: rgb(255, 255, 255);
    border: 1px solid #eee;
    // margin-bottom: 20px;
    font-family: 'Montserrat';
    box-shadow: -3px 5px 8px 0px rgba(166,150,150,0.3);
    -webkit-box-shadow: -3px 5px 8px 0px rgba(166,150,150,0.3);
    -moz-box-shadow: -3px 5px 8px 0px rgba(166,150,150,0.3);
    border-radius: 5px;
    font-family: 'Montserrat';
}

.product:hover {
    background-color: #d8d8d825;
    cursor: pointer;
    color: #fff;
}

.product-not-active{
    background-color: #5e5d5d25;
}

.input-cash{
    border: none;
    border-bottom: 1px solid #797878;
    width: 300px;
    text-align: center;
    font-size: 50px;
    -webkit-appearance: none;
    margin: 0;
}

.input-cash:focus{
    outline: none;
}

input::-webkit-outer-spin-button,
input::-webkit-inner-spin-button {
  -webkit-appearance: none;
  margin: 0;
}

.accounts::-webkit-scrollbar {
  display: none;
}

